$enable-negative-margins : true;

$spacer: 1rem;
$spacers: (
        0: 0,
        8: 0.8rem,
        10: 1rem,
        12: 1.2rem,
        13: 1.3rem,
        15: 1.5rem,
        16: 1.6rem,
        20: 2rem,
        23: 2.3rem,
        24: 2.4rem,
        25: 2.5rem,
        30: 3rem,
        36: 3.6rem,
        39: 3.9rem,
        40: 4.0rem,
        44: 4.4rem,
        48: 4.8rem,
        64: 6.4rem,
        67: 6.7rem,
        75: 7.5rem,
        85: 8.5rem,
        88: 8.8rem,
);