// Mettre à jour ce lien via : https://fonts.google.com/

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600;700;800;900&family=Inter:wght@400;500;600&family=Georama:wght@700&display=swap');

$font-vw: ();

$ecran: 1920px;

$basevw: 100vw;

/* ======= fonts à déclarer
*/
$fonts: ();
$fonts: (
        inter:'Inter',
        raleway:'Raleway',
        georama:'Georama',
);

$font-family-base: map-get($fonts,inter);
$font-size-base : 1.6rem;

$font-sizes: ();
$font-sizes: (
        13: 1.3rem,
        15: 1.5rem,
        16: 1.6rem,
        18: 1.8rem,
        20: 2rem,
        22: 2.2rem,
        25: 2.5rem,
        28: 2.8rem,
        34: 3.4rem,
        36: 3.6rem,
        40: 4rem,
        48: 4.8rem,
        128: 12.8rem,
);

/* ======= fonts weight
*/

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-bolder:          800;
$font-weight-black:          800;

$font-weight-base:            $font-weight-normal;

/* ======= line height
*/

$line-height: ();
$line-height: (
        28: 2.8rem,
        30: 3rem,
        33: 3.3rem,
        37: 3.7rem,
        48: 4.8rem,
);

/* ======= utilities
*/

$utilities: map-merge(
                $utilities,(
                "font-family":(
                        rfs:true,
                        property: font-family,
                        responsive: true,
                        class: font,
                        values: $fonts
                ),
                "font-size": (
                        //rfs: true,
                        property: font-size,
                        responsive: true,
                        class: fs,
                        values: $font-sizes
                ),
                "font-weight": (
                        property: font-weight,
                        class: fw,
                        responsive: true,
                        values: (
                                light: $font-weight-light,
                                normal: $font-weight-normal,
                                medium: $font-weight-medium,
                                semi-bold: $font-weight-semibold,
                                bold: $font-weight-bold,
                                bolder: $font-weight-bolder,
                                black: $font-weight-black,
                        )
                ),
                "line-height": (
                        property: line-height,
                        class: lh,
                        responsive: true,
                        values: $line-height
                ),
                "text-color": (
                        property: color,
                        class: text,
                        responsive: true,
                        values: $theme-colors
                ),
        )
);